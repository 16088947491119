<template>
  <div class="loading-page">
    <div class="tips">
      <span>{{ message }}</span>
      <!-- <button @click="clickBtn">按钮</button> -->
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { wechatConfig } from '@/utils/wechat.js'
import { reactive, toRefs, onMounted, onActivated, onDeactivated } from 'vue'
export default {
  name: '',
  data() {
    return {
      message: '',
      timer: null,
      isFirstShow: true, // 第一次加载;
      pageState: null // 消息卡片跳转到loading页面状态码;
    }
  },
  created() {
    console.log('create', document)
    this.initPage()
  },
  setup(props) {
    console.log('props', props)
    // vue3 mounted事件;
    onMounted(() => {
      console.log('mounted')
    })
    onActivated(() => {
      wx.closeWindow()
      console.log('activated')
    })
    onDeactivated(() => {
      console.log('onDeactivated')
    })
  },
  methods: {
    initPage() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '跳转中...'
      })
      let externalUserIds = this.$route.query.externalUserIds
      console.log(this.$route.query)
      if (externalUserIds) {
        this.onOpenChat(externalUserIds)
      } else {
        Toast.clear()
        Toast('跳转错误,请提供正确的用户ID')
        this.message = '请重试...'
      }
    },
    clickBtn() {
      this.$router.push({
        name: 'workbench'
      })
    },
    onOpenChat(externalUserIds) {
      console.log('onOpenChat--externalUserIds', externalUserIds)
      wechatConfig((wx) => {
        wx.openEnterpriseChat({
          externalUserIds: externalUserIds,
          chatId: '',
          success: (res) => {
            Toast.clear()
            this.pageState = 1
            this.message = '跳转中...'
            console.log('openEnterpriseChat-history', history.state)
          },
          fail: (res) => {
            Toast.clear()
            // 请重试
            this.message = ''
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          },
          complete: (res) => {
            console.log('complete', res)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.loading-page .tips {
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
